.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn {
  font-weight: 500 !important;
}

.ant-pagination-item-active a {
  color: #ffff !important;
}

.ant-pagination-options {
  display: none !important;
}

.mp-table .ant-table {
  @apply shadow-header;
}

/* start mp table antd style */
.mp-table .ant-table .ant-table-thead > tr > .ant-table-cell {
  @apply border-0 border-b border-solid border-b-neutral-300 bg-neutral-0 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.mp-table .ant-table tr > .ant-table-cell {
  font-size: 14px !important;
}

.mp-table .ant-table-wrapper .ant-table .ant-table-tbody > tr > td {
  @apply border-0 border-b border-solid border-b-neutral-100 !important;
}

.mp-table .ant-table-wrapper .ant-table .ant-table-tbody > *:nth-child(odd),
.ant-table-wrapper .ant-table .ant-table-tbody > *:nth-child(even) {
  @apply bg-neutral-0;
}

.mp-table .ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  @apply bg-neutral-0;
}

.mp-table th.ant-table-cell::before {
  background-color: transparent !important;
}

/* end mp table antd style */

/* start whatsapp template table antd style */
.whatsapp-template-selector-modal-table .ant-table .ant-table-thead > tr > .ant-table-cell {
  background-color: white !important;
  border-bottom: 1px solid #d2d2d2 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.whatsapp-template-selector-modal-table .ant-table-wrapper .ant-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d2d2d2 !important;
}

.whatsapp-template-selector-modal-table
  .ant-table-wrapper
  .ant-table
  .ant-table-tbody
  > *:nth-child(odd),
.ant-table-wrapper .ant-table .ant-table-tbody > *:nth-child(even) {
  background-color: white !important;
}

.whatsapp-template-selector-modal-table
  .ant-table-wrapper
  .ant-table
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td {
  background-color: white !important;
}

/* end whatsapp template table antd style */

.preview-email-content-container .PreviewSpecific__precoding {
  padding: 0 0.125rem !important;
  background-color: #ececec;
  border: 1px solid #949494;
  display: inline-block;
  border-radius: 4px;
}

.ant-alert.ant-alert-error {
  border: 1px solid #e50000;
}

.document::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.document {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.phone-number-button .ant-input-group .ant-input-group-addon {
  padding: 0 8px;
  background-color: white !important;
  border-right: 1px solid white;
}

.phone-number-button .ant-input-group .ant-input-group-addon .ant-select-selector {
  border: none;
}

.phone-number-button
  :where(.css-dev-only-do-not-override-1cektqd).ant-input-group
  .ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-select-dropdown:has(:where(.precoding-dropdown)) {
  border-radius: 8px !important;
  padding: 8px !important;
  z-index: 200;
}

.ant-select-dropdown:has(:where(.precoding-dropdown)) .ant-select-item-option-state {
  display: none;
}

.ant-select-dropdown:has(:where(.precoding-dropdown)) .ant-select-item-option-selected {
  background-color: #e6f5ea !important;
}

.precoding-dropdown .rc-virtual-list-holder-inner .ant-select-item {
  padding: 6px !important;
}

.precoding-node {
  background-color: #ececec;
  border-color: #949494;
}

.precoding-node::before {
  content: '{';
}

.precoding-node::after {
  content: '}';
}

.whatsapp-editor-container .active-precoding-node {
  @apply border-blue-500 bg-blue-50;
}

.template-select .ant-select-selector {
  background-color: transparent !important;
}

.custom-select .ant-select-selector {
  background-color: transparent !important;
  height: 40px !important;
  padding: 0 16px !important;
}

.ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px;
}

.ant-select-selection-search {
  height: 40px;
}

.ant-select-selection-selected-value {
  border-radius: 0px 8px 8px 0px;
  height: 40px;
}

.ant-select-selection.ant-select-selection--single {
  height: 40px;
}

.template-input,
.ant-input-affix-wrapper-disabled {
  background-color: transparent !important;
}

.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.show-more-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.show-more-link {
  text-decoration: underline;
  cursor: pointer;
  color: #0d6aa8;
}

/* .ant-tooltip-inner:has(.custom-tooltip) {
  color: #000 !important;
  padding: 16px 24px !important;
  background-color: ;
} */

.no-form-item-padding .ant-form-item-label {
  padding: 0 !important;
}

.custom-carousel .slick-dots li button {
  @apply rounded-full border border-solid border-[#0364D4] bg-white opacity-100;
}

.custom-carousel .slick-active button {
  @apply bg-[#0364D4] !important;
}

.custom-carousel li.slick-active {
  @apply !important;
}

.custom-carousel .slick-dots-bottom {
  @apply bottom-0 top-[200px] !important;
}

.category-table .custom-table .ant-table .ant-table-thead > tr > .ant-table-cell {
  @apply border-0 bg-white text-base !important;
}

.custom-checkbox .ant-checkbox-inner {
  @apply h-5 w-5 !important;
}

.custom-checkbox .ant-checkbox-inner::after {
  @apply h-3 !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  @apply border-blue-500 bg-white !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  @apply !bg-blue-500;
  inset-block-start: 15% !important;
  inset-inline-start: 14% !important;
  width: 33px !important;
  height: 33px !important;
}
.layout-sidebar {
  @apply shadow-header;
}

.ant-select-focused.precoding-dropdown .ant-select-selector {
  @apply !border-blue-500;
}

.precoding-dropdown .ant-select-selector {
  box-shadow: none !important;
}

p:has(span.sqeui-toast-label) {
  width: 100% !important;
}

.compact-container .group\/editor {
  @apply !py-3;
}

.dropdown-container .group\/editor {
  @apply !p-1;
}

.input-number-container .group\/editor {
  @apply !px-0 !pb-[3px] !pt-0;
}

.input-number-container .ant-input-number-group-addon {
  @apply !border-none !bg-transparent;
}

.dropdown-container .group\/editor label,
.input-number-container .group\/editor label {
  @apply !z-20;
}

li:has(.disabled-checkbox-select) {
  cursor: auto !important;
}
