.ant-drawer .ant-drawer-mask {
  background: none;
}

.ant-drawer {
  z-index: 0;
}

.ant-drawer-content-wrapper {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04) !important;
}
