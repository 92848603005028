.ant-menu-item.ant-menu-item-selected {
  @apply rounded-none border-0 border-b-4 border-solid border-bold-green bg-transparent font-semibold text-white !important;
}
.ant-menu-item:hover {
  @apply bg-transparent transition-all !important;
}
.ant-menu-item {
  @apply my-4 h-fit whitespace-normal pb-2	pl-0 leading-7 !important;
  padding-inline: 0 !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  @apply ml-2 bg-transparent !important;
}

.ant-menu-submenu-title {
  @apply p-0 !important;
}

.ant-menu-submenu-title:hover {
  @apply bg-transparent !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
  .ant-menu-submenu-title {
  @apply overflow-visible !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-title-content {
  @apply rounded-none border-0 border-b-4 border-solid border-bold-green bg-transparent font-semibold text-white !important;
}
